<template>
    <Row :gutter="20"> 
        <Col span='8' style="border-right: 1px solid #eee;">
            <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="unique_tournament_id" ></uniqueTournamentEdit>
        </Col>
        <Col span='16' style="border-left: 1px solid #eee;">
            <seasonList :sport_id="sport_id" :unique_tournament_id="unique_tournament_id" ></seasonList>
        </Col>
    </Row>
</template>
<script>

import uniqueTournamentEdit from './edit.vue';
import seasonList from '../season/list.vue';

export default {
    name: "unique-tournament-detail",
    props: {
        sport_id: Number,   // 运动id
        unique_tournament_id: Number,   // 联赛id
    },
    components: {
        uniqueTournamentEdit,
        seasonList
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>